import React from 'react';
import {useTranslation} from "gatsby-plugin-react-i18next";
const Popup = (props) => {

    const {t} = useTranslation();

    return (


    <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-content box" >
            <p>{t('are_you_want_delete')}</p>
         <div className={'buttons'}>
             <button onClick={props.handleDeleteFalse}  className="button is-danger">{t('cancel')}</button>
             <button onClick={props.handleDeleteTrue} className="button is-success">
                 {t('delete')}
             </button>
         </div>
        </div>


    </div>

    );
}

export default Popup;

/*
   <button className="modal-close button is-danger" aria-label="close"></button>
 */